<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <Sessions></Sessions>
      </div>
    </div>
    <div class="row" v-if="currentCards.length === 0">
      <div class="col-xl-12">
        <div
          class="alert alert-custom alert-notice alert-light-info fade show"
          role="alert"
        >
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">
            Olá, <b>{{ name }}</b> você precisa registrar um cartão de crédito
            caso queria realizar um upgrade de plano.
          </div>
          <div class="alert-close">
            <router-link class="btn btn-info" to="/profile#payment"
              >Cadastrar Cartão</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <plans></plans>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sessions from "@/view/content/widgets/list/Sessions.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import plans from "./comps/plans";
export default {
  name: "plansIndex",
  components: {
    Sessions,
    plans
  },
  data() {
    return {
      cards: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "plansIndex" }]);
  },
  computed: {
    ...mapGetters(["currentUser", "currentCards"]),
    name() {
      return this.currentUser.name;
    }
  }
};
</script>
