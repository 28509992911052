<template>
  <div class="card-body position-relative p-0 rounded-card-top">
    <h3
      class="bg-success text-white text-center py-10 py-lg-20 m-0 rounded-card-top"
    >
      Planos
      <br />
      <br />

      <div class="btn-group " role="group" aria-label="Large button group">
        <button
          type="button"
          class="btn text-uppercase"
          :class="[type === 'month' ? 'btn-primary' : 'btn-outline-primary']"
          @click="setType('month')"
        >
          Mensal
        </button>
        <button
          type="button"
          class="btn text-uppercase"
          :class="[type === 'yearly' ? 'btn-primary' : 'btn-outline-primary']"
          @click="setType('yearly')"
        >
          Anual
        </button>
      </div>
    </h3>
    <!--end::Pricing title-->
    <div class="row justify-content-center m-0 position-relative">
      <!-- begin: Custom background-->

      <div
        class="d-none d-lg-block bg-success position-absolute w-100 h-100"
      ></div>
      <!-- end: Custom background-->

      <div class="col-11">
        <div class="row" v-if="Object.keys(plansCmp).length > 0">
          <template v-for="(pConfig, plan) in order">
            <div :class="pConfig.class" :key="plan" v-if="plansCmp[plan]">
              <div class="py-15 px-0 px-lg-5 text-center">
                <div class="d-flex flex-center mb-7">
                  <i
                    class="icon-5x text-primary"
                    :class="plansCmp[plan].configs.icon"
                  ></i>
                </div>
                <h4 class="font-size-h3 mb-10 text-dark text-capitalize">
                  {{ plansCmp[plan].name }}
                </h4>
                <div class="row">
                  <template v-if="plansCmp[plan].priceYearly">
                    <span
                      class="font-size-h1 col-12 font-weight-boldest text-dark text-capitalize"
                    >
                      <template v-if="type === 'month'">
                        {{ plansCmp[plan].price }} / mês</template
                      >
                      <template v-else>
                        {{ plansCmp[plan].yearly.price }} / ano</template
                      >
                    </span>
                  </template>
                  <template v-else>
                    <span
                      class="font-size-h1 col-12 font-weight-boldest text-dark text-capitalize"
                      >{{ plansCmp[plan].price }}
                    </span>
                  </template>
                </div>
                <!--begin::Mobile Pricing Table-->
                <div class="d-lg-none">
                  <div
                    class="py-3"
                    :class="option.class"
                    v-for="(option, idx) in options"
                    :key="`opt-${plan}-${idx}`"
                  >
                    <span class="font-weight-boldest">
                      <template v-if="option.type === 'text'">
                        {{ option.title }}
                      </template>
                      <template v-else>
                        <template v-if="plansCmp[plan].configs[idx]">
                          {{ option.title }}
                        </template>
                        <template v-else>
                          <del>{{ option.title }}</del>
                        </template>
                      </template>
                    </span>
                    <span class="ml-5">
                      <template v-if="option.type === 'text'">
                        {{ plansCmp[plan].configs[idx] }}
                      </template>
                      <template v-if="option.type === 'check'">
                        <i
                          v-if="plansCmp[plan].configs[idx]"
                          class="text-primary flaticon2-check-mark"
                        ></i>
                        <i v-else class="text-primary flaticon2-delete"></i>
                      </template>
                    </span>
                  </div>
                </div>
                <!--end::Mobile Pricing Table-->
                <div class="mt-7" v-if="pConfig.purchase">
                  <template v-if="selectedPlan(plansCmp[plan])">
                    <button
                      type="button"
                      class="btn btn-success text-uppercase font-weight-bolder px-15 py-3"
                    >
                      Selecionado
                    </button>
                  </template>

                  <template v-else>
                    <!-- <button
                      type="button"
                      class="btn btn-primary text-uppercase font-weight-bolder px-15 py-3"
                      @click="selectPlan(plansCmp[plan])"
                    >
                      Selecionar 2
                    </button> -->

                    <modal
                      v-if="currentUser.plan._id && plansCmp[plan]._id"
                      :config="pConfig"
                      :type="type"
                      :current="currentUser.plan"
                      :target="plansCmp[plan]"
                      :options="options"
                      :cards="cards"
                    ></modal>
                  </template>
                </div>
                <div class="mt-7" v-else>
                  <a
                    :href="linkWhats()"
                    type="button"
                    class="btn btn-primary text-uppercase font-weight-bolder px-15 py-3"
                  >
                    Entrar em contato
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <tablePlans
      :order="order"
      :options="options"
      :plans="plansCmp"
    ></tablePlans>
  </div>
</template>

<script>
import modal from "./modal";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import tablePlans from "./tablePlans";
import Swal from "sweetalert2";
import { UPDATE_USER } from "@/core/services/store/auth.module";
export default {
  name: "plans",
  props: ["cards"],
  components: {
    tablePlans,
    modal
  },
  data() {
    return {
      plans: [],
      type: "month",
      sequence: 1,
      order: {
        Start: {
          class: "offset-lg-3 col-12 col-lg-3 bg-white p-0",
          yearly: true,
          purchase: true
        },
        Profissional: {
          class:
            "col-12 col-lg-3 bg-white border-x-0 border-x-lg border-y border-y-lg-0 p-0",
          yearly: true,
          purchase: true
        },
        Enterprise: {
          class: "col-12 col-lg-3 bg-white mb-10 mb-lg-0 p-0",
          yearly: true,
          purchase: true
        }
      },
      options: {
        domain: {
          title: "Domínios",
          type: "text",
          class: "bg-gray-100"
        },
        account: {
          title: "Contas",
          type: "text"
        },
        views: {
          title: "Visitantes",
          type: "text",
          class: "bg-gray-100"
        },
        banner: {
          title: "Banner Consentimento",
          type: "check"
        },
        block: {
          title: " Bloqueio Automatico de Cookies",
          type: "check",
          class: "bg-gray-100"
        },
        pages: {
          title: "Paginas Ilimitadas",
          type: "check"
        },
        crawler: {
          title: "Identificaçao de Cookies Automatica",
          type: "check",
          class: "bg-gray-100"
        },
        optOut: {
          title: "Opt-out através do seu website",
          type: "check"
        },
        stamp: {
          title: "Selo seguro by Consensu",
          type: "check",
          class: "bg-gray-100"
        },
        gtm: {
          title: "Integração via GTM",
          type: "check"
        },
        wordpress: {
          title: "Plugin para Wordpress",
          type: "check",
          class: "bg-gray-100"
        },
        customize: {
          title: "Cores e Textos Customizados",
          type: "check"
        },
        privacy: {
          title: "Politica de Privacidade",
          type: "check",
          class: "bg-gray-100"
        },
        terms: {
          title: "Termos de Uso",
          type: "check"
        },
        stats: {
          title: "Estatisticas de Consentimento",
          type: "check",
          class: "bg-gray-100"
        },
        audit: {
          title: "Auditoria de Consentimento",
          type: "check"
        },
        logo: {
          title: "Utilize seu logotipo",
          type: "check",
          class: "bg-gray-100"
        },
        hide: {
          title: "Esconder aviso apos aceite",
          type: "check"
        },
        export: {
          title: "Exportação de dados",
          type: "check",
          class: "bg-gray-100"
        },
        api: {
          title: "Acesso a API de Consentimento",
          type: "check"
        },
        reCrawler: {
          title: "Reagendamento de Identificação de Cookies",
          type: "check",
          class: "bg-gray-100"
        },
        privacyCookies: {
          title: "Politica de Privacidade de Cookies",
          type: "check",
          class: "bg-gray-100"
        }
      }
    };
  },
  created() {
    this.getPlans();
    this.type = this.currentUser.plan.type;
    this.sequence = this.currentUser.plan.sequence;
  },
  computed: {
    ...mapGetters(["currentUser"]),
    plansCmp() {
      let cmp = {};
      this.plans.map(plan => {
        cmp[plan.name] = plan;
      });
      return cmp;
    }
  },
  methods: {
    mobileCheck() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some(toMatchItem => {
        return window.navigator.userAgent.match(toMatchItem);
      });
    },
    linkWhats() {
      if (this.mobileCheck()) {
        return "https://api.whatsapp.com/send?phone=5511950563199&text=Quero saber mais sobre o plano Enterprise";
      } else {
        return "https://web.whatsapp.com/send?phone=5511950563199&text=Quero saber mais sobre o plano Enterprise";
      }

      //
    },
    selectedPlan(target) {
      if (this.type === "month") {
        return this.currentUser.plan._id === target._id;
      }
      if (this.type === "yearly" && target.yearly) {
        return this.currentUser.plan._id === target.yearly._id;
      }
      return false;
    },
    yearly(plan) {
      return plan ? plan._id : 0;
    },
    selectPlan(plan) {
      let selected = plan;
      if (this.type === "yearly") {
        selected = plan.yearly;
      }
      ApiService.post(`/users/subscribe`, {
        plan: selected._id,
        iugu: this.currentUser.iugu
      })
        .then(({ data }) => {
          console.log("SUBSCRIBE", data);
          let user = this.currentUser;
          user.plan = plan;
          this.$store.dispatch(UPDATE_USER, user);
          Swal.fire({
            title: "Deu certo!",
            text: "Cartão adicionado com sucesso!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            text: "Tivemos algum problema para processar essa interação.",
            icon: "error",
            confirmButtonClass: "btn btn-danger"
          });
        });
    },
    setType(type) {
      this.type = type;
    },
    getPlans() {
      ApiService.post(`/plans/all`, {
        query: {
          visible: true
        }
      }).then(({ data }) => {
        this.plans = data.docs;
      });
    }
  }
};
</script>

<style>
h3 {
  z-index: 9999;
}
.switchMonthYear {
  z-index: 9999;
}
</style>
