<template>
  <div class="">
    <div class="row">
      <div class="col-12 text-center" v-if="!isTrial">
        <h1>Downgrade de plano</h1>
      </div>
      <div class="col-12 text-center" v-else>
        <h1>Seleção de plano</h1>
      </div>
    </div>

    <div class="row list">
      <div class="col-5 plan">
        <div class="d-flex flex-center mb-7">
          <h1>Atual</h1>
        </div>

        <div class="d-flex flex-center mb-7">
          <i class="icon-5x text-primary" :class="current.configs.icon"></i>
        </div>
        <h4 class="font-size-h3 mb-10 text-dark text-center text-capitalize">
          {{ current.name }}
        </h4>
        <div class="row text-center">
          <span
            class="font-size-h1 col-12 font-weight-boldest text-dark text-capitalize"
          >
            <template v-if="type === 'month'">
              <template v-if="current.price === 'grátis'">
                {{ current.price }}
              </template>
              <template v-else> {{ current.price }} / mês </template>
            </template>
            <template v-else>
              <template v-if="current.yearly">
                {{ current.yearly.price }} / ano
              </template>
              <template v-else>
                {{ current.price }}
              </template>
            </template>
          </span>
          <b-button
            class="mt-3"
            block
            variant="success"
            v-if="!isTrial"
            @click="hideModal"
            >Manter</b-button
          >
        </div>
      </div>
      <div class="col-2 text-center justify-center justify-content-center">
        <i class="icon-2x text-dark-50 flaticon2-right-arrow arrow-upgrade"></i>
      </div>

      <div class="col-5 plan">
        <div class="d-flex flex-center mb-7">
          <h1>Escolhido</h1>
        </div>

        <div class="d-flex flex-center mb-7">
          <i class="icon-5x text-primary " :class="target.configs.icon"></i>
        </div>
        <h4 class="font-size-h3 mb-10 text-center text-dark text-capitalize">
          {{ target.name }}
        </h4>
        <div class="row">
          <span
            class="font-size-h1 text-center col-12 font-weight-boldest text-dark text-capitalize"
          >
            <template v-if="type === 'month' || !target.yearly">
              {{ target.price }} / mês
            </template>
            <template v-else> {{ target.yearly.price }} / ano </template>
          </span>
          <b-button
            class="mt-2"
            block
            variant="outline-danger"
            @click="handleOk"
            v-if="!isTrial"
            >Fazer Downgrade</b-button
          >

          <b-button
            class="mt-2"
            block
            variant="success"
            @click="handleOk"
            v-else
            >Selecionar plano</b-button
          >
        </div>
      </div>
      <div class="col-12 mt-10">
        <div
          class="d-flex align-items-center justify-content-between flex-lg-wrap flex-xl-nowrap"
        >
          <div class="d-flex flex-column mr-5">
            <div class="row  mb-7">
              <h1 class="text-h1 col-12">Alteração de plano</h1>
              <div class="text-muted col-12">
                Seu plano ficará com as seguintes opções
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="item in intersect" :key="item">
                <span
                  class="label label-lg label-inline downgrade-label mb-2"
                  :class="{
                    'label-light-info': isTrial,
                    'label-light-danger': !isTrial
                  }"
                >
                  <template
                    v-if="options[item] && options[item].type === 'text'"
                  >
                    {{ target.configs[item] }}
                  </template>
                  <template v-else>
                    {{ options[item].title }}
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-plan-upgrade",
  props: ["type", "current", "target", "hide", "options", "cards"],
  computed: {
    intersect() {
      let target = this.current.configs;
      let current = this.target.configs;
      let array = [];
      Object.keys(target).forEach(opt => {
        if (target[opt] && opt != "icon" && target[opt] != current[opt]) {
          array.push(opt);
        }
      });
      return array;
    },
    isTrial() {
      return this.current.name === "Trial";
    }
  },
  methods: {
    showModal() {
      this.modal.show();
    },
    hideModal() {
      this.hide();
    },
    toggleModal() {
      // this.modal.hide();
      this.hide();
      // this.modal.toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      this.$router.push({
        path: `/plans/downgrade/${this.target._id}/${this.type}`
      });
      this.hide();
    }
  }
};
</script>

<style>
.list {
  position: relative;
}
.arrow-upgrade {
  position: absolute;
  top: 40%;

  width: 100%;
  left: 0;
}
.downgrade-label {
  /* border-radius: 0.4rem !important; */
  font-size: 12px;
  font-weight: 500;
  /* margin: 5px;  */
  padding: 20px !important;
}
</style>
