<template>
  <div class="upgrade">
    <div class="row mb-16 ">
      <div class="col-12 text-center text-uppercase">
        <h1>Upgrade de plano</h1>
      </div>
    </div>
    <div class="row list" v-if="haveCard">
      <div class="col-5 plan">
        <div class="d-flex flex-center mb-7 text-uppercase">
          <h1>Atual</h1>
        </div>
        <div class="d-flex flex-center mb-7">
          <i class="icon-5x text-primary" :class="current.configs.icon"></i>
        </div>
        <h4 class="font-size-h3 mb-10 text-dark text-center text-capitalize">
          {{ current.name }}
        </h4>
        <div class="row text-center">
          <span
            class="font-size-h1 col-12 font-weight-boldest text-dark text-capitalize"
          >
            <template v-if="type === 'month'">
              <template v-if="current.price === 'grátis'">
                {{ current.price }}
              </template>
              <template v-else> {{ current.price }} / mês </template>
            </template>
            <template v-else>
              <template v-if="current.expiration === 'month'">
                {{ current.price }} / mês
              </template>
              <template v-else>
                <template v-if="current.yearly">
                  {{ current.yearly.price }}
                </template>
                <template v-else>
                  {{ current.price }}
                </template>
              </template>
            </template>
          </span>
          <b-button
            class="mt-3"
            block
            variant="outline-primary"
            @click="hideModal"
            >Manter</b-button
          >
        </div>
      </div>
      <div class="col-2 text-center justify-center justify-content-center">
        <i class="icon-2x text-dark-50 flaticon2-right-arrow arrow-upgrade"></i>
      </div>
      <div class="col-5 plan">
        <div class="d-flex flex-center mb-7  text-uppercase">
          <h1>Escolhido</h1>
        </div>

        <!-- {{ target }} -->
        <div class="d-flex flex-center mb-7">
          <i class="icon-5x text-primary " :class="target.configs.icon"></i>
        </div>
        <h4 class="font-size-h3 mb-10 text-center text-dark text-capitalize">
          {{ target.name }}
        </h4>
        <div class="row">
          <span
            class="font-size-h1 text-center col-12 font-weight-boldest text-dark text-capitalize"
          >
            <template v-if="type === 'month'">
              {{ target.price }} / mês
            </template>
            <template v-else>
              <template v-if="target.yearly">
                {{ target.yearly.price }} / ano
              </template>
              <template v-else>
                {{ target.price }}
              </template>
            </template>
          </span>
          <b-button
            class="mt-2"
            :class="loading"
            block
            variant="success"
            @click="handleOk"
            >Fazer Upgrade</b-button
          >
        </div>
      </div>
      <!-- {{ options }} -->
      <div class="col-12 mt-10" v-if="benefits.length > 2">
        <div
          class="d-flex align-items-center justify-content-between flex-lg-wrap flex-xl-nowrap"
        >
          <!-- {{ options }} -->
          <div class="d-flex flex-column mr-5">
            <div class="row  mb-7">
              <h1 class="text-h1 col-12">Benefícios</h1>
              <div class="text-muted col-12">
                Lista com todos os itens que você terá acesso!
              </div>
            </div>
            <div class="row">
              <div class="col-6" v-for="benefit in benefits" :key="benefit">
                <span
                  class="label label-lg label-light-info label-inline upgrade-label mb-2"
                >
                  <template v-if="options[benefit].type === 'text'">
                    {{ target.configs[benefit] }}
                  </template>
                  <template v-else>{{ options[benefit].title }} </template>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card card-custom mb-8 mb-lg-0">
          <div class="card-body">
           
          </div>
        </div> -->
        <!-- <h3 class="text-h2">Benefícios</h3>
            <br />
            <span class="label label label-pill label-inline mr-2 "
              >Politica de Psuccessel label label-pill label-inline mr-2 "
              >Termos de Uso</span
            >
            <span class="label label label-pill label-inline mr-2"
              >Estatisticas de Consentimento</span
            >
            <span class="label label label-pill label-inline mr-2"
              >Auditoria de Consentimento</span
            > -->
      </div>
    </div>

    <div v-else>
      <div class="alert  alert-custom alert-secondary" role="alert">
        <div class="alert-icon">
          <i class="flaticon-warning"></i>
        </div>
        <div class="alert-text">
          Você precisa registrar um cartão de crédito
        </div>
      </div>
      <creditCard></creditCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import creditCard from "./creditCard.vue";
export default {
  name: "modal-plan-upgrade",
  props: ["type", "current", "target", "hide", "options"],
  data() {
    return {
      loading: "",
      addCreditCard: false
    };
  },
  components: {
    creditCard
  },
  mounted() {
    this.$events.off("add-credit-card");
    this.$events.on("add-credit-card", result => {
      this.addCreditCard = result;
    });
  },
  computed: {
    ...mapGetters(["currentUser", "currentCards"]),
    haveCard() {
      return this.currentCards.length > 0 || this.addCreditCard;
    },
    benefits() {
      let target = this.target.configs;
      let current = this.current.configs;
      let array = [];
      Object.keys(target).forEach(opt => {
        if (target[opt] && opt != "icon" && target[opt] != current[opt]) {
          array.push(opt);
        }
      });
      return array;
    }
  },
  methods: {
    showModal() {
      this.modal.show();
    },
    hideModal() {
      this.hide();
    },
    toggleModal() {
      // this.modal.hide();
      this.hide();
      // this.modal.toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      this.loading = "spinner spinner-white spinner-right";
      let selected = this.target;
      if (this.type === "yearly") {
        selected = this.target.yearly ? this.target.yearly : this.target;
      }
      ApiService.post(`/users/subscribe`, {
        plan: selected._id,
        iugu: this.currentUser.iugu
      })
        .then(() => {
          let user = this.currentUser;
          user.plan = selected;
          this.$store.dispatch(UPDATE_USER, user);
          Swal.fire({
            title: "Deu certo!",
            text: "Upgrade realizado com sucesso!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
          this.hide();
          this.loading = "";
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            text: "Tivemos algum problema para processar essa interação.",
            icon: "error",
            confirmButtonClass: "btn btn-danger"
          });
          this.hide();
          this.loading = "";
        });
    }
  }
};
</script>

<style>
.upgrade {
  padding: 24px;
}
.list {
  position: relative;
}
.arrow-upgrade {
  position: absolute;
  top: 40%;

  width: 100%;
  left: 0;
}

.upgrade-label {
  /* border-radius: 0.4rem !important; */
  font-size: 12px;
  font-weight: 500;
  /* margin: 5px;  */
  padding: 20px !important;
}
</style>
