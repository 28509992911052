<template>
  <div class="btn-modal-plan">
    <button
      type="button"
      @click="showModal"
      class="btn btn-primary text-uppercase font-weight-bolder px-15 py-3"
    >
      Selecionar
    </button>
    <b-modal
      :ref="modalName"
      size="lg"
      centered
      hide-header
      hide-footer
      :id="modalName"
    >
      <template v-if="componentType === 'upgrade'">
        <upgrade
          :type="type"
          :current="current"
          :target="target"
          :modal="modal"
          :hide="hideModal"
          :options="options"
        ></upgrade>
      </template>
      <template v-else>
        <downgrade
          :type="type"
          :current="current"
          :target="target"
          :modal="modal"
          :hide="hideModal"
          :options="options"
        ></downgrade>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2";
// import JwtService from "@/core/services/jwt.service";
// import axios from "axios";
// import ApiService from "@/core/services/api.service";
import upgrade from "./upgrade";
import downgrade from "./downgrade";

export default {
  name: "modal-plan-select",
  props: ["config", "type", "current", "target", "options"],
  components: {
    upgrade,
    downgrade
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showModal() {
      this.$refs[this.modalName].show();
    },
    hideModal() {
      this.$refs[this.modalName].hide();
    },
    toggleModal() {
      this.$refs[this.modalName].toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      console.log("OK");
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    modal() {
      return this.$refs[this.modalName];
    },
    modalName() {
      return `modal-plan-select`;
    },
    componentType() {
      return this.current.sequence <= this.target.sequence
        ? "upgrade"
        : "downgrade";
    }
  }
};
</script>

<style scoped>
.btn-modal-code {
  width: 35px;
  float: left;
  right: 0;
}
.list {
  position: relative;
}
.arrow-upgrade {
  position: absolute;
  top: 40%;

  width: 100%;
  left: 0;
}
</style>
