<template>
  <div class="row justify-content-center mx-0 mb-15 d-none d-lg-flex">
    <div class="col-11">
      <div
        class="row  py-5 font-weight-bold text-center"
        :class="option.class"
        v-for="(option, idx) in options"
        :key="idx"
      >
        <div class="col-3 text-left px-5 font-weight-boldest">
          {{ option.title }}
        </div>
        <!-- <template v-for></template> -->
        <div class="col-3" v-for="(pConfig, plan) in order" :key="plan">
          <!-- {{ plans[plan].configs[idx] }} -->
          <template v-if="plans[plan]">
            <template v-if="option.type === 'text'">
              {{ plans[plan].configs[idx] }}
            </template>
            <template v-if="option.type === 'check'">
              <!-- {{ plansCmp[plan].configs[idx] }} -->
              <i
                v-if="plans[plan].configs[idx]"
                class="text-primary flaticon2-check-mark"
              ></i>
              <i v-else class="text-primary flaticon2-delete"></i>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tablePlans",
  props: ["order", "plans", "options"]
};
</script>

<style></style>
